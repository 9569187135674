<template>
	<Popup name="grs"
	text="<h3>Gare routière sud</h3><br/><div class='popup__statistics'><p class='dark__red'>Quelques chiffres</p><p>6 postes de chargement camion</p><p>Environ 10 000 camions de bitume / an</p></div><br/><p>Elle permet aux camions de charger du bitume. Elle possède également des ponts de pesée réglementaires pour les camions faisant entrer ou sortir des produits de la raffinerie (soufre, huiles végétales, matières recyclables, …). La raffinerie possède une seconde gare routière (au Nord) équipée de 14 postes de chargement, destinés aux produits finis.</p>"
	video="static/video/grs.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/GRS_di2yii_c_scale_w_350.jpg 350w,
		img/popup/GRS_di2yii_c_scale_w_708.jpg 708w,
		img/popup/GRS_di2yii_c_scale_w_970.jpg 970w"
		data-src="img/popup/GRS_di2yii_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
